const fetchOptimized = async (request: Request) => {
  const workerUrl = new URL('https://lbbz.turboengine.io/')
  const originalUrl = new URL(request.url)

  workerUrl.pathname = originalUrl.pathname
  workerUrl.search = originalUrl.search
  workerUrl.hash = originalUrl.hash

  // Create a new RequestInit object with only the properties we need from the original request.
  const requestInit: RequestInit = {
    method: request.method,
    headers: request.headers,
    body: request.body,
    mode: 'cors', // This can be 'cors', 'no-cors', or 'same-origin'.
    credentials: request.credentials,
    cache: request.cache,
    redirect: request.redirect,
    referrer: request.referrer,
    integrity: request.integrity,
  }

  const workerRequest = new Request(workerUrl.href, requestInit)

  // Fetch the optimized resource from your worker.
  const workerResponse = await fetch(workerRequest)

  // Return the worker's response, or fall back to the original resource if the worker fails.
  return workerResponse.ok ? workerResponse : fetch(request)
}

self.addEventListener('fetch', (event) => {
  console.log(`Fetch ${event.request.url}`, event)
  event.respondWith(fetchOptimized(event.request))
})

export {}
